import React, { useState, useEffect, Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BurgerMenus from './BurgerMenus';
import logo from "../img/logo/logo.png"

const HeaderStyleFive = () => {

    const [menuOpen, setMenuOpen] = useState(false)
    const [searchOpen, setSearchOpen] = useState(false)

   // const [path, setPath] = useState("")
	// useEffect(() => {
	// 	setPath(router.pathname)
	// }, [router])

    // Sticky Menu Area start
    useEffect(() => {
        window.addEventListener('scroll', sticky);
        return () => {
            window.removeEventListener('scroll', sticky);
        };
    });

    const sticky = (e) => {
        const header = document.querySelector('.header__area');
        const scrollTop = window.scrollY;
        scrollTop >= 1 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };
    // Sticky Menu Area End

    return (
            <header>
                <div id="header-sticky" className="header__area header__transparent header__padding-2">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12 ">
                                <div className="header__left d-flex justify-content-center">
                                    <div className="logo">
                                        <a href="/">
                                            <a>
                                                <img className="logo-white mr-30" src={logo} style={{width:"200px"}} alt="logo" />
                                            </a>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-4 col-xl-4 d-none d-xl-block">
                                
                            </div>
                            <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="header__right d-flex justify-content-end align-items-center">
                                    <div className="header__btn header__btn-2 ml-30 d-none d-sm-block">
                                        <a href="/sign-up"><a className="g-btn">Découvrez le programme</a></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
    );
}

export default HeaderStyleFive;