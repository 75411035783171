import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import DirecteurImage from '../img/Directeur/Directeur-auto.jpg'
import Qoute from '../img/icon/icone-doyen.png'


class Directeur extends Component {

    render() {

        return (
        <section className="why__area pt-50 mb-40">
            <div className="container">
               <div className="row align-items-center justify-content-between">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                     <div className="why__content mt-40">
                        <div className="section__title-wrapper pr-0 mb-30">
                           <h2 className="section__title">MOT <span className="yellow-bg yellow-bg-big"> DU DIRECTION<img src={banneryellow} alt="img not found" /></span></h2>
                              <img src={Qoute} />
                           <p className='pt-15'>
                           Bienvenue à la filière communication et médias, nouvellement créée au sein du Collège HSPJS en prélude à l’établissement dans le court terme de l’Institut des Hautes Etudes des Communications Sociales Afrique (IHECS Afrique). Cette nouvelle filière vient à point nommé pour enrichir et élargir l’offre en formations académiques diplômantes de l’Université Internationale de Rabat.
                           </p>
                           <p>
                           Forte d’un solide partenariat avec le prestigieux Institut des Hautes Etudes en Communication Sociale de Bruxelles (qui est la première école de communication et de médias en Belgique et une des plus anciennes en Europe), l’IHECS a été conçue pour répondre à un besoin croissant en professionnels de haut calibre dans les domaines de la communication et des médias ; ce besoin a été clairement affirmé par les organisations publiques et semi publiques, les entreprises et les institutions nationales et internationales.
                           </p>
                           <p>
                           Abdellatif Bencherifa
                           <span className='d-block'>Doyen du Collège Humanités et Sciences Politiques,Juridiques, Sociales</span>
                           </p>
                        </div>
                        <div className="why__btn">
                           <a href="/contact"><a className="g-btn e-btn-3 mr-30">Rejoignez-nous</a></a>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                     <div className="why__thumb">
                        <img src={DirecteurImage} alt="img not found" className='w-100' />
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Directeur;