import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import image1 from '../img/etude/Students-1.jpg';
import image2 from '../img/etude/Students-2.jpg';
import banneryellow from '../img/shape/BG-1.png'

class Service extends Component {

    render() {

        return (
        <section className="about__area pt-70 pb-70">
            <div className="container">
               <div className="row">
                  <div className="col-xxl-5 col-xl-5 col-lg-5 d-flex justify-content-center align-items-center">
                     <div className="about__thumb-wrapper">
                        <div className="about__thumb ml-100">
                           <img src={image1} alt="img not found" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-7 col-xl-7 col-lg-7">
                     <div className="about__content pl-70 pr-60 pt-25">
                        <div className="section__title-wrapper mb-25">
                           <h2 className="section__title">
                              <span className="yellow-bg-big">
                              Organisation  <img src={banneryellow} alt="img not found" />
                           </span> Des Études
                              </h2>
                        </div>
                        <div className="about__list mb-35">
                    
                           <ul className='service'>
                              <li className="d-flex align-items-center">
                              Offre aux étudiants des bases solides et plurivalentes en communication conventionnelle et digitale et dans les domaines des médias. Cycle de 3 ans qui permet une spécialisation progressive des étudiants dans les domaines de Publicité, de Relations Publiques et de Journalisme et qui mène vers une polyvalence permettant de s’adapter aux divers métiers, aux situations professionnelles et aux enjeux nationaux et internationaux.
                              </li>
                              
                           </ul>
                        </div>
                        <a className="e-btn">Voir Plus</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Service;